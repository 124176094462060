import './BrivoTenants.style.scss'

import React, {useEffect} from 'react'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import Column from '../../components/Grid/Column'
import InputText from '../../components/InputText/InputText'
import Button from '../../components/Button'
import Section from '../../components/Grid/Section'
import InputSelect from '../../components/InputSelect'
import Panel from '../../components/Panel'
import Icon from '../../components/Icon'
import {useSearchParams} from 'react-router-dom'
import Spinner from '../../components/Spinner'

import useBrivoTenants from './useBrivoTenants'

const BrivoTenants = () => {
  const [allParams] = useSearchParams({code: ''})

  const authCode = allParams.get('code')
  const authorizedTenant = allParams.get('state')

  const {
    apiKeyValue,
    tenantOptions,
    selectedTenant,

    isLoading,
    isRedirecting,
    hasStoredSecrets,
    isStoreApiKeyLoading,
    isTenantSecretLoading,

    setApiKey,
    submitApiKey,
    setSelectedTenant,
    completeAuthentication,
  } = useBrivoTenants()

  useEffect(() => {
    if (authCode && authorizedTenant) {
      completeAuthentication(authCode, authorizedTenant)
    }
  }, [authCode, authorizedTenant, completeAuthentication])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'BrivoTenants'} data-testid={'BrivoTenants'}>
        <Section spacing='md'>
          <h3>Brivo Tenant Secrets</h3>
        </Section>

        <Panel theme={'white'}>
          <Column lg={'6'} md={'12'}>
            <form
              id={'brivo-tenants-form'}
              className={'BrivoTenants-form'}
              data-testid={'BrivoTenantsForm'}
            >
              <InputSelect
                id={'tenant-select'}
                allowNull={!tenantOptions.length}
                size={'md'}
                label={'Select Tenant'}
                value={selectedTenant || ''}
                options={tenantOptions}
                onValueChange={setSelectedTenant}
              />
              <InputText
                disabled={!selectedTenant}
                label={'Brivo API Key'}
                value={apiKeyValue}
                placeholder='api-key obtained from developer.brivo.com'
                onValueChange={value => setApiKey(value.toString())}
              />

              <Button
                size={'lg'}
                className={'submit-button'}
                loading={isStoreApiKeyLoading}
                disabled={
                  isRedirecting ||
                  !selectedTenant ||
                  (!apiKeyValue && !hasStoredSecrets) ||
                  isStoreApiKeyLoading
                }
                onClick={submitApiKey}
              >
                {isRedirecting ? 'Redirecting ...' : 'Authorize'}
              </Button>

              <Spinner overlay visible={isTenantSecretLoading} />
            </form>
          </Column>
        </Panel>
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(BrivoTenants)
