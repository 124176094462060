import './BrivoTenants.style.scss'

import {useCallback, useEffect, useState} from 'react'
import useAllTenants from '../../hooks/data/useAllTenants'
import {useSearchParams} from 'react-router-dom'
import useTenantSecrets from '../../hooks/data/useTenantSecrets'

import {useMutation} from '@apollo/client'
import {
  STORE_BRIVO_TOKEN,
  STORE_TENANT_SECRET,
} from '../../data/graphql/mutations/utilities'
import {
  TStoreBrivoTokenResponse,
  TStoreBrivoTokenVariables,
  TStoreTenantSecretResponse,
  TStoreTenantSecretVariables,
} from '../../data/graphql/mutations/utilities/types'
import useToast from '../../hooks/useToast'
import {
  DeviceManufacturerNameEnum,
  SecretTypeNameEnum,
} from '../../data/graphql/queries/enums'
import {DeviceMaker} from '../../data/graphql/types'

const clientId = process.env.REACT_APP_BRIVO_CLIENT_ID || ''

const useBrivoTenants = () => {
  const [allParams] = useSearchParams({code: ''})

  const tenants = useAllTenants()
  const secrets = useTenantSecrets()
  const {showErrorToast, showInfoToast} = useToast()
  const [storeSecret, storeSecretResult] = useMutation<
    TStoreTenantSecretResponse,
    TStoreTenantSecretVariables
  >(STORE_TENANT_SECRET)

  const [storeToken] = useMutation<TStoreBrivoTokenResponse, TStoreBrivoTokenVariables>(
    STORE_BRIVO_TOKEN,
    {
      onError: () => {
        showErrorToast('Failed to store token', "We couldn't store the token")
      },
      onCompleted: () => {
        showInfoToast('Token stored', 'Token has been stored successfully')
      },
    },
  )

  const authorizedTenant = allParams.get('state')

  const [apiKeyValue, setApiKey] = useState('')
  const [isRedirecting, setRedirectingFlag] = useState(false)
  const [tenantOptions, setTenantOptions] = useState<{label: string; value: string}[]>([])
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null)

  const hasStoredSecrets = Number(secrets.data?.length) > 0

  const submitApiKey = async () => {
    if (!selectedTenant || (!apiKeyValue && !hasStoredSecrets)) {
      return
    }

    try {
      if (apiKeyValue) {
        await storeSecret({
          variables: {
            input: {
              tenantUuid: selectedTenant,
              secret: apiKeyValue,
              deviceManufacturer: DeviceManufacturerNameEnum.BRIVO,
              secretType: SecretTypeNameEnum.API_KEY,
            },
          },
        })
      }

      setRedirectingFlag(true)

      window.location.href = `https://auth.brivo.com/oauth/authorize?client_id=${clientId}&response_type=code&state=${selectedTenant}`
    } catch (e) {
      setRedirectingFlag(false)
      showErrorToast('Failed to store API key', 'API key has not been stored')
    }
  }

  const completeAuthentication = useCallback(
    (code: string, tenantUuid: string) => {
      storeToken({
        variables: {
          input: {
            code,
            tenantUuid,
            deviceMaker: DeviceMaker.BRIVO,
          },
        },
      })
    },
    [storeToken],
  )

  useEffect(() => {
    if (!tenants.data?.length || selectedTenant) {
      return
    }

    const newOptions = tenants.data.map(tenant => ({
      label: tenant.tenantName,
      value: tenant.tenantUuid,
    }))

    setTenantOptions(newOptions)

    if (newOptions.length > 0) {
      const optionToSelect = newOptions.find(option => option.value === authorizedTenant)

      setSelectedTenant(optionToSelect?.value || newOptions[0].value)
    }
  }, [tenants, selectedTenant, authorizedTenant])

  useEffect(() => {
    const refetchSecrets = secrets.refetch

    if (selectedTenant) {
      refetchSecrets(selectedTenant)
    }
  }, [selectedTenant, secrets.refetch])

  return {
    apiKeyValue,
    tenantOptions,

    isRedirecting,
    hasStoredSecrets,
    isLoading: tenants.loading,
    isTenantSecretLoading: secrets.loading,
    isStoreApiKeyLoading: storeSecretResult.loading,

    selectedTenant,
    setSelectedTenant,
    setApiKey,
    submitApiKey,
    completeAuthentication,
  }
}

export default useBrivoTenants
